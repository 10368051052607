import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FieldErrorDisplayComponent } from '../field-error-display/field-error-display.component';
import { HeaderComponent } from '../header/header.component';
import { ServiceHeaderComponent } from '../service-header/service-header.component';
import { FooterComponent } from '../footer/footer.component';
import { FormsModule } from '@angular/forms';
import { PagesComponent } from './pages.component';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { JobMenuComponent } from './job-menu/job-menu.component';
import { ModalComponent } from './../../_directives';
import { ModalService } from './../../_services';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
  declarations: [
    FieldErrorDisplayComponent,
    HeaderComponent,
    ServiceHeaderComponent,
    FooterComponent,
    PagesComponent,
    JobMenuComponent, ModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    LazyLoadImageModule,
    NgbModule.forRoot()
  ],
  providers: [ModalService],
  exports: [FieldErrorDisplayComponent, HeaderComponent, ServiceHeaderComponent, JobMenuComponent, ModalComponent, FooterComponent, FormsModule, PagesComponent, NgbModule, LazyLoadImageModule]
})
export class PagesModule { }
