import { Component, OnInit } from '@angular/core';
import { ScriptLoaderService } from '../../_services/script-loader.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: '.m-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  currentYear = new Date().getFullYear();
  locationArr: any = [];
  defaultAddress: any = {};
  constructor(private _script: ScriptLoaderService, private toastr: ToastrService) { }

  ngOnInit() {
    this._script.getData('api/get_office_locations', false).subscribe(
      (data) => {
        if (data.msg == 'Success') {
          let index = data.locations.findIndex(el => el.is_default == '1');
          if (index != null && index != undefined && index >= 0) {
            this.defaultAddress = data.locations[index];
            data.locations.splice(index, 1);
            this.locationArr = data.locations;
          }
        } else {
          this.toastr.error("Something Went Wrong!!!!", 'Oops!!');
        }
      },
      (error) => {
        let err = (error.error.errorDetails) ? error.error.errorDetails : 'Oops, Error Occurred!!';
        this.toastr.error(err, 'Oops!!');
      });
  }

}
