import { Component, AfterViewInit, OnInit } from '@angular/core';
import { ScriptLoaderService } from './_services/script-loader.service';
import { NavigationEnd, Router, NavigationStart } from '@angular/router';

declare let $:any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  constructor(private _script: ScriptLoaderService, private router: Router) { }
  ngOnInit() {
  }

  ngAfterViewInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.events.subscribe((route) => {
      if (route instanceof NavigationStart) {
        // if (route.url == '/index' || route.url == '/') {
        //   $("body").removeClass("no-transition");
        // }
        // document.getElementById("page-whole-spinner").style.display = "table";
      }
      if (route instanceof NavigationEnd) {
        this._script.loadScripts('app-root', ['assets/js/plugins.js', 'assets/js/functions.js']);
        window.scrollTo(0, 0);
      }
    });
  }

}

