import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../../_services/modal.service';
import { ToastrService } from 'ngx-toastr';
import { ScriptLoaderService } from '../../../_services/script-loader.service';
import { Router } from "@angular/router";

declare let $: any;
@Component({
  selector: 'app-job-menu',
  templateUrl: './job-menu.component.html',
  styleUrls: ['./job-menu.component.css']
})
export class JobMenuComponent implements OnInit {
  data: any = { 'type': 'signIn' };
  forgetData: any = { 'UserName': '' };
  signInData: any = { 'email': '', 'password': '' };
  forgetSubmitted = false;
  submitted = false;
  currentLoggedInUser = JSON.parse(localStorage.getItem('currentRadiantUser'));
  constructor(private modalService: ModalService, private toastr: ToastrService, private _script: ScriptLoaderService, private router: Router) {
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    document.getElementById("page-whole-spinner").style.display = "none";
  }

  signin(loginForm) {
    this.submitted = true; console.log(this.signInData);
    if (loginForm.valid) {
      $('.form-process').css("display", "block");
      this._script.getData('api/SignInUser?UserName=' + this.signInData.email + "&password=" + this.signInData.password, false, true).subscribe(
        (data) => {
          console.log(data)
          if (data && data.length > 0) {
            this.submitted = false;
            this.toastr.success('Thank you for joining with us!!');
            localStorage.setItem('currentRadiantUser', JSON.stringify(data[0]));
            this.modalService.close('signIn')
            if (data[0] && data[0]['ResumeUploaded'] && data[0]['ResumeUploaded'] == 'Yes') {
              this.router.navigate(["/job-board"]);
            } else {
              this.router.navigate(["/manage-resume"]);
            }
          } else {
            let err = (data.errorDetails) ? data.errorDetails : 'Oops, Error Occurred!!';
            this.toastr.error(err, 'Oops!!');
            $('.form-process').css("display", "none")
          }
        },
        (error) => {
          if (error.status == 404) {
            this.toastr.error("Entered Wrong Email or Password!!!", 'Oops!!');
            $('.form-process').css("display", "none");
          }
          else {
            this.toastr.error('Oops, Error Occurred!!', 'Oops!!');
            $('.form-process').css("display", "none");
          }
        });
    }
  }

  forgotSubmit(forgetForm) {
    this.forgetSubmitted = true;
    if (forgetForm.valid) {
      $('.form-process').css("display", "block");
      this._script.postData('api/ForgotPassword?UserName=' + this.forgetData.UserName, {}, true).subscribe(
        (data) => {
          console.log(data)
          if (data && data.length > 0) {
            this.submitted = false;
            $("#new-password").text(data[0].password);
            $("#new-col-for-password").css("display", "block");
            this.toastr.success('Cool! Password recovery instruction has been sent to your email');
            this.forgetSubmitted = false;
            this.signInData = { 'email': '', 'password': '' };
            this.forgetData = { 'email': '' };
            $('.form-process').css("display", "none");
          } else {
            let err = (data.errorDetails) ? data.errorDetails : 'Oops, Error Occurred!!';
            this.toastr.error(err, 'Oops!!');
            $('.form-process').css("display", "none");
          }
        },
        (error) => {
          $('.form-process').css("display", "none")
          let err = (error.error.errorDetails) ? error.error.errorDetails : 'Oops, Error Occurred!!';
          this.toastr.error(err, 'Oops!!');
        });
    }
  }

  doLogout() {
    localStorage.removeItem('currentRadiantUser');
    this.router.navigate(["/job-board"]);
  }

  goToForgotPassword() {
    this.data.type = 'forgot';
    $("#new-col-for-password").css("display", "none");
  }
}