import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';

@Injectable()
export class TitleService implements Resolve<any> {
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title, private meta: Meta
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map((route) => {
                while (route.firstChild) {
                    route = route.firstChild;
                };

                return route;
            }),
            filter((route) => route.outlet === 'primary'),
            mergeMap((route) => route.data)
        ).subscribe((event) =>
            this.updateDescription(event['description'], event['title'])
        );
    }

    updateDescription(desc: string, title: string) {
        this.titleService.setTitle(title);
        this.meta.updateTag({ name: 'description', content: desc });
    }
}