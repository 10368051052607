import { NgModule } from '@angular/core';
import { ThemeComponent } from './theme.component';
import { Routes, RouterModule } from '@angular/router';
import { TitleService } from "../_services/title.service";

const routes: Routes = [
    {
        "path": "",
        "component": ThemeComponent,
        resolve: { someKey: TitleService },
        "children": [
            {
                "path": "index",
                "loadChildren": ".\/pages\/index\/index.module#IndexModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "contingent-staffing-service",
                "loadChildren": ".\/pages\/contingent-staffing-service\/contingent-staffing-service.module#ContingentStaffingServiceModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "technical-staffing",
                "loadChildren": ".\/pages\/technical-staffing\/technical-staffing.module#TechnicalStaffingModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "engineering-staffing",
                "loadChildren": ".\/pages\/engineering-staffing\/engineering-staffing.module#EngineeringStaffingModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "industrial-staffing",
                "loadChildren": ".\/pages\/industrial-staffing\/industrial-staffing.module#IndustrialStaffingModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "professional-staffing",
                "loadChildren": ".\/pages\/professional-staffing\/professional-staffing.module#ProfessionalStaffingModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "scientific-clinical-staffing",
                "loadChildren": ".\/pages\/scientific-clinical-staffing\/scientific-clinical-staffing.module#ScientificClinicalStaffingModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "industries",
                "loadChildren": ".\/pages\/industries\/industries.module#IndustriesModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "industry-detail/:name",
                "loadChildren": ".\/pages\/industry-detail\/industry-detail.module#IndustryDetailModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "about-us",
                "loadChildren": ".\/pages\/about-us\/about-us.module#AboutUsModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "career",
                "loadChildren": ".\/pages\/career\/career.module#CareerModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "register",
                "loadChildren": ".\/pages\/register\/register.module#RegisterModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "my-application",
                "loadChildren": ".\/pages\/my-application\/my-application.module#MyApplicationModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "profile",
                "loadChildren": ".\/pages\/profile\/profile.module#ProfileModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "contact-us",
                "loadChildren": ".\/pages\/contact-us\/contact-us.module#ContactUsModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "application-development-management",
                "loadChildren": ".\/pages\/application-development-management\/application-development-management.module#ApplicationDevelopmentManagementModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "master-data-management",
                "loadChildren": ".\/pages\/master-data-management\/master-data-management.module#MasterDataManagementModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "data-warehousing-business-intelligence",
                "loadChildren": ".\/pages\/data-warehousing-business-intelligence\/data-warehousing-business-intelligence.module#DataWarehousingBusinessIntelligenceModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "legacy-system-maintenance",
                "loadChildren": ".\/pages\/legacy-maintenance\/legacy-maintenance.module#LegacyMaintenanceModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "enterprise-business-solution",
                "loadChildren": ".\/pages\/enterprise-business-solution\/enterprise-business-solution.module#EnterpriseBusinessSolutionModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "testing-validation-service",
                "loadChildren": ".\/pages\/testing-validation-service\/testing-validation-service.module#TestingValidationServiceModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "rad-digital",
                "loadChildren": ".\/pages\/rad-digital\/rad-digital.module#RadDigitalModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "msp-vms",
                "loadChildren": ".\/pages\/msp-vms\/msp-vms.module#MspVmsModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "rad-workforce-solution",
                "loadChildren": ".\/pages\/rad-workforce-solution\/rad-workforce-solution.module#RadWorkforceSolutionModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "direct-hire-services",
                "loadChildren": ".\/pages\/direct-hire-services\/direct-hire-services.module#DirectHireServicesModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "microsoft-dynamics",
                "loadChildren": ".\/pages\/microsoft-dynamics\/microsoft-dynamics.module#MicrosoftDynamicsModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "cloud-transformation",
                "loadChildren": ".\/pages\/cloud-transformation\/cloud-transformation.module#CloudTransformationModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "platform-service/:name",
                "loadChildren": ".\/pages\/sap\/sap.module#SAPModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "rpo",
                "loadChildren": ".\/pages\/rpo\/rpo.module#RpoModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "master-vendor-program",
                "loadChildren": ".\/pages\/master-vendor-program\/master-vendor-program.module#MasterVendorProgramModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "privacy-policy",
                "loadChildren": ".\/pages\/privacy-policy\/privacy-policy.module#PrivacyPolicyModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "cookie-policy",
                "loadChildren": ".\/pages\/cookie-policy\/cookie-policy.module#CookiePolicyModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "sitemap",
                "loadChildren": ".\/pages\/sitemap\/sitemap.module#SitemapModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "data-breach-policy-procedures",
                "loadChildren": ".\/pages\/data-breach-policy-procedures\/data-breach-policy-procedures.module#DataBreachPolicyProceduresModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "data-retention-erasure-policy",
                "loadChildren": ".\/pages\/data-retention-erasure-policy\/data-retention-erasure-policy.module#DataRetentionErasurePolicyModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "it-staffing",
                "loadChildren": ".\/pages\/it-staffing\/it-staffing.module#IndustriesModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "it-staffing-detail/:name",
                "loadChildren": ".\/pages\/it-staffing-detail\/it-staffing-detail.module#ItStaffingDetailModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "clients",
                "loadChildren": ".\/pages\/clients\/clients.module#ClientsModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "sow",
                "loadChildren": ".\/pages\/sow\/sow.module#SowModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "rad-service/:name",
                "loadChildren": ".\/pages\/rad-service\/rad-service.module#RadServiceModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "job-board",
                "loadChildren": ".\/pages\/job-board\/job-board.module#JobBoardModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "job-detail/:jobId",
                "loadChildren": ".\/pages\/job-detail\/job-detail.module#JobDetailModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "manage-resume",
                "loadChildren": ".\/pages\/manage-resume\/manage-resume.module#ManageResumeModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "platform-services",
                "loadChildren": ".\/pages\/platform-services\/platform-services.module#PlatformServicesModule",
                "data": { "title": "Radiant Systems Inc", "description": "" }
            },
            {
                "path": "",
                "redirectTo": "index",
                "pathMatch": "full"
            }
        ]
    },
    {
        "path": "**",
        "redirectTo": "404",
        "pathMatch": "full"
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ThemeRoutingModule { }