import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ThemeComponent } from './theme/theme.component';
import { TitleService } from "./_services/title.service";

const routes: Routes = [
    {
        path: '', "component": ThemeComponent, loadChildren: './theme/pages/index/index.module#IndexModule', resolve: { someKey: TitleService },
        "data": { "title": "Welcome To Radiants", "description": "Welcome To Radiants" }
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }