import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ThemeComponent } from './theme/theme.component';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from './app-routing.module';
import { ScriptLoaderService } from "./_services/script-loader.service";
import { TitleService } from "./_services/title.service";
import { ThemeRoutingModule } from "./theme/theme-routing.module";
import { PagesModule } from "./theme/pages/pages.module";
import { AppComponent } from './app.component';
import { HttpClientModule } from "@angular/common/http";
import { WindowRef } from './_services/window-ref.service';
import { ToastrModule } from 'ngx-toastr';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
  declarations: [
    ThemeComponent,
    AppComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ThemeRoutingModule,
    PagesModule,
    ToastrModule.forRoot(),
    LazyLoadImageModule
  ],
  providers: [ScriptLoaderService, TitleService, WindowRef],
  bootstrap: [AppComponent]
})
export class AppModule {
}
