import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: "app-theme",
    templateUrl: "./theme.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class ThemeComponent implements OnInit {
    constructor() { }

    ngOnInit() {
    }

}