import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-field-error-display',
    templateUrl: "field-error-display.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class FieldErrorDisplayComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() field: string;
    @Input() f: FormGroup;
    account_validation_messages: any;
    constructor() { }

    ngOnInit() {
        this.account_validation_messages = {
            'fullName': [
                { type: 'required', message: 'Name is required' },
                { type: 'minlength', message: 'Name must be at least 2 characters long' },
                { type: 'maxlength', message: 'Name cannot be more than 50 characters long' },
                { type: 'pattern', message: 'First character should not contain space,Your Name must contain only Characters' }
            ],
            'school': [
                { type: 'required', message: 'School Name is required' },
                { type: 'minlength', message: 'School Name must be at least 2 characters long' },
                { type: 'maxlength', message: 'School Name cannot be more than 150 characters long' },
                { type: 'pattern', message: 'First character should not contain space' }
            ],
            'phone': [
                { type: 'required', message: 'Phone Number is required' },
                { type: 'minlength', message: 'Phone Number must be at least 10 characters long' },
                { type: 'maxlength', message: 'Phone Number cannot be more than 10 characters long' },
                { type: 'pattern', message: 'Phone Number must contain only Numbers' },
                { type: 'number', message: 'Phone Number must contain only Numbers' }
            ],
            'booksCount': [
                { type: 'required', message: 'Enter number of books you want' },
                { type: 'pattern', message: 'Phone Number must contain only Numbers' },
                { type: 'number', message: 'Number of books must contain only Numbers' }
            ],
            'email': [
                { type: 'required', message: 'Email is required' },
                { type: 'email', message: 'Invalid Email' },
                { type: 'pattern', message: 'Invalid Email' },
            ],
            'subject': [
                { type: 'required', message: 'Subject is required' }
            ],
            'comment': [
                { type: 'required', message: 'Comment is required' }
            ],
            'message': [
                { type: 'required', message: 'Message is required' }
            ],
            'tc': [
                { type: 'required', message: 'Please select Accept Terms  and Conditions' }
            ],
            'question': [
                { type: 'required', message: 'Please enter your Question' }
            ],
            'companyName': [
                { type: 'required', message: 'Company Name is required' }
            ],
            'college': [
                { type: 'required', message: 'College Name is required' }
            ],
            'resume': [
                { type: 'required', message: 'Please upload your resume' }
            ],
            'address': [
                { type: 'required', message: 'Address is required' },
                { type: 'minlength', message: 'Address should contain minimum of 2 characters long' },
                { type: 'minlength', message: 'Address should not contain  more than 150 characters long' },
                { type: 'pattern', message: "First Character Should Not Contain Space" }
            ],

            'zipCode': [
                { type: 'required', message: 'zipCode is required' },
            ],
            'city': [
                { type: 'required', message: 'City is required' },
            ],
            'state': [
                { type: 'required', message: 'State is required' },
            ],
            'workshopType': [
                { type: 'required', message: 'Please select Type' },
            ],
            'duration': [
                { type: 'required', message: 'Please select Class Type' },
            ],
            'country': [
                { type: 'required', message: 'Country is required' },
            ],
            'password': [
                { type: 'required', message: 'Password is required' },
                { type: 'minlength', message: 'Password must be at least 3 characters long' },
                { type: 'maxlength', message: 'Password cannot be more than 15 characters long' },
                { type: 'pattern', message: "Your Password must contain only numbers and letters and allowed characters are '&@%*#'" }
                //  { type: 'validUsername', message: 'Your username has already been taken' }
            ],
            'confirmPassword': [
                { type: 'required', message: 'Confirm Password is required' },
                { type: 'minlength', message: 'Confirm Password must be at least 3 characters long' },
                { type: 'maxlength', message: 'Confirm Password cannot be more than 15 characters long' },
                { type: 'pattern', message: "Your Confirm Password must contain only numbers and letters and allowed characters are '&@%*#'" },
                { type: 'equalTo', message: 'Password Mismatch' }
            ],
        };
    }
}
