import { Injectable } from '@angular/core';
//import * as $ from 'jquery';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from "@angular/common/http"
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch';

declare var $: any;
declare let mApp: any;
interface Script {
    src: string;
    loaded: boolean;
}

@Injectable()
export class ScriptLoaderService {
    env = environment;
    public _scripts: Script[] = [];
    url = this.env.apiURL;
    customURL = this.env.apiIntranetRadApis
    constructor(private http: HttpClient) { }

    /**
     * Lazy load list of scripts
     * @param tag
     * @param scripts
     * @param loadOnce
     * @returns {Promise<any[]>}
     */
    loadScripts(tag, scripts, loadOnce?: boolean) {
        loadOnce = loadOnce || false;

        scripts.forEach((script: string) => {
            if (!this._scripts[script]) {
                this._scripts[script] = { src: script, loaded: false };
            }
        });

        let promises: any[] = [];
        scripts.forEach(
            (script) => promises.push(this.loadScript(tag, script, loadOnce)));

        return Promise.all(promises);
    }

    loadScript(tag, src: string, loadOnce?: boolean) {
        loadOnce = loadOnce || false;

        if (!this._scripts[src]) {
            this._scripts[src] = { src: src, loaded: false };
        }

        return new Promise((resolve, reject) => {
            // resolve if already loaded
            if (this._scripts[src].loaded && loadOnce) {
                resolve({ src: src, loaded: true });
            }
            else {
                // load script tag
                let scriptTag = $('<script/>').
                    attr('type', 'text/javascript').
                    attr('src', this._scripts[src].src);

                $(tag).append(scriptTag);

                this._scripts[src] = { src: src, loaded: true };
                resolve({ src: src, loaded: true });
            }
        });
    }

    getData(link, type, isIntranetURL?: boolean): Observable<any> {
        let url = isIntranetURL ? this.env.apiIntranetURL : this.url;
        if (type) {
            return this.http.get(url + link, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Auth': sessionStorage.getItem('humaci-User-X-Auth') }) })
                .map((response: HttpResponse<any>) => response,
                    (error: HttpErrorResponse) => error);
        } else {
            return this.http.get(url + link, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
                .map((response: HttpResponse<any>) => response,
                    (error: HttpErrorResponse) => error);
        }
    }

    postData(link, data, isIntranetURL?: boolean, customURL?: boolean, token?: string): Observable<any> {
        let url = customURL ? this.customURL : isIntranetURL ? this.env.apiIntranetURL : this.url;
        return this.http.post(url + link, data, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }) })
            .map((response: HttpResponse<any>) => response,
                (error: HttpErrorResponse) => error);
    }

    getApiUrl() {
        return this.url;
    }

    getAssetUrl() {
        return this.url + 'assets/images/';
    }
}