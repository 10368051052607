import { Component, OnInit } from '@angular/core';
import { ScriptLoaderService } from '../../_services/script-loader.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: '.app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  industryArr: any = [];
  radServiceArr: any = [];
  platformServiceArr: any = [];
  platformMainService: any = {};
  constructor(private _script: ScriptLoaderService, private toastr: ToastrService) { }

  ngOnInit() {
    this._script.getData('api/get_industries_radservices_name', false).subscribe(
      (data) => {
        if (data.msg == 'Success') {
          this.industryArr = data.industries;
          this.radServiceArr = data.radServices;
          this.platformServiceArr = data.platformServices;
          this.platformMainService = data.main_service;
        }
        else {
          this.toastr.error("Something Went Wrong!!!!", 'Oops!!');
        }
      },
      (error) => {
        let err = (error.error.errorDetails) ? error.error.errorDetails : 'Oops, Error Occurred!!';
        this.toastr.error(err, 'Oops!!');
      });
  }

}
